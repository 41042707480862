export const firebaseConfig = {
  apiKey: 'AIzaSyC5-RRluyV-gloKNtahPMEumoCqSaGGPj8',
  authDomain: 'wonder-ocracy.firebaseapp.com',
  databaseURL: 'https://wonder-ocracy.firebaseio.com',
  storageBucket: 'wonder-ocracy.appspot.com',
  projectId: 'wonder-ocracy',
  messagingSenderId: "149888453646",
  // This loads the Event planning page - Set to 2,3 to change it
  // Or leave it empty to show all the tasks as the default page
  defaultPageToLoad: '' //'?filter=taskType&text=3'
};
