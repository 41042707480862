export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const REMOVE_PROJECT_ERROR = 'REMOVE_PROJECT_ERROR';
export const REMOVE_PROJECT_SUCCESS = 'REMOVE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const UNLOAD_PROJECTS_SUCCESS = 'UNLOAD_PROJECTS_SUCCESS';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const NEW_PROJECT_CREATED = 'NEW_PROJECT_CREATED';
